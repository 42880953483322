import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopupComponent implements OnInit {

  contador: number;
  constructor() { }

  ngOnInit() { }

}
