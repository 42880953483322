import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { Cell, DefaultEditor, Editor } from 'ng2-smart-table';

@Component({
  template: `
  <input 
  style="min-width: 140px;"
  currencyMask 
  class="form-control"
  [(ngModel)]="cell.newValue" 
  [options]="{ prefix: '', thousands: '.', decimal: ',' }"
  min="0"
  max="100"
  >
  `
})
export class CustomEditorPercentageComponent extends DefaultEditor implements AfterViewInit {
    
  constructor() {
    super();
  }

  ngAfterViewInit() {
    if (this.cell.newValue !== '') {
      //TODO:
    }
  }
}