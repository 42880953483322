import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FileService{

  constructor(private http: HttpClient) {}

  downloadFile(filePath: string): Observable<Blob> {
    const fileName = filePath.split('/').pop;
    const headers = new HttpHeaders({
      'Content-Disposition': `attachment, filename="${fileName}"`
    });

    return this.http.get(filePath, { responseType: 'blob', headers })

    // return this.http.get(filePath, { responseType: 'blob'});
  };
}
