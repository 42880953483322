import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { FileService } from '../file-service/file.service';

@Component({
  selector: 'app-download-file',
  templateUrl: './custom-download-file.component.html',
  styleUrls: ['./custom-download-file.component.scss']
})
export class CustomDownloadFileComponent implements ViewCell, OnInit {

  @Input() value: any;
  @Input() rowData: any;

  constructor(private fileService: FileService) { }

  ngOnInit() {
  }

  downloadFile(): void {
    const filePath = 'assets/arquivos/' + this.rowData.nomeArquivo;
    this.fileService.downloadFile(filePath).subscribe(data => {
      const blob = new Blob([data], { type: 'application/octet-strem' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filePath.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }
}
